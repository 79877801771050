import React from 'react';
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SelectField from '../SharedComponents/SelectField/SelectField';
import './CreateOrEditUserModal.scss';
import { mockUserDetails } from './mock';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import SchedulerService from '../../services/service';
import * as Constants from '../../constants/constants';
import Radio from '@material-ui/core/Radio';
import MessageModal from '../SharedComponents/MessageModal';
import messages from '../../Common/Messages.json';
import { validateEmail } from '../../Common/Helper';
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelIcon from '@material-ui/icons/Cancel';
import { withUserContext } from '../../contexts/UserContext';
import { getFormattedDate, getDateAndTime } from "../../Common/Helper";
import SearchSelectField from '../SharedComponents/SearchSelectField';
import sizeLimits from '../../Common/SizeLimits.json';

class CreateOrEditUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            userDetails: JSON.parse(JSON.stringify({ ...mockUserDetails })),
            tenantDetails: [],
            userRoleDetails: [],
            fieldValid: true,
            postInitiated: false,
            fieldChanged: false,
            showSuccess: false,
            showFail: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            isPosting: false,
            loadingData: false,
            existingEmail: false
        }
    }

    componentDidMount() {
        this.getUserRole();
        this.getTenants();
        this.getGroups();
        if (this.props?.title === "Edit User") {
            this.getEditUser(this.props?.id);
        }
    }

    getGroups = () => {
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + '/group?groupId=null', this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                let groupData = response?.data?.map(item => ({ value: item?.group_id, text: item?.group_name }))
                this.setState({ groupData: groupData })
            }, err => {
                console.log("err", err)
            })
    }

    getEditUser = (id) => {
        this.setState({ loadingData: true })
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + `/user?userId=${id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                let formattedList = response.data[0]
                this.setState({ userDetails: formattedList, loadingData: false })
            },
                (err) => {
                    this.setState({ loadingData: false })
                    console.log("Error in fetching UserRole Details:", err)
                })
    }

    getUserRole = () => {
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + '/staticData?staticTable=role', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ userRoleDetails: formattedList });
            },
                (err) => {
                    console.log("Error in fetching UserRole Details:", err)
                })
    }

    getTenants = () => {
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + '/staticData?staticTable=tenant', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {

                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ tenantDetails: formattedList, });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    closeState = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        }
        else {
            this.props?.handleClose();
        }
    }

    onConfirm = () => {
        this.props?.handleClose();
        this.props?.handleRefresh();
    }

    handleChange = (field, value) => {
        if (field === "email") {
            if (value) {
                let emailExists = false
                let validEmail = validateEmail(value)
                this.setState({ fieldValid: validEmail });
                if (validEmail && this.props?.title === 'New User') {
                    emailExists = this.props?.emailList.find(item => item.email === value.toLowerCase())
                    emailExists ? this.setState({ existingEmail: true }) : this.setState({ existingEmail: false })
                }
            } else {
                this.setState({ fieldValid: true });
            }
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value,
                },
            }));
        }
        else {
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value,
                },
            }));
        }
        this.fieldChanged(true, "error")
    }

    handleCheck = (e, field) => {
        let checked = e.target.checked
        let userDetails = this.state.userDetails
        if (checked) {
            userDetails[field] = 0
        }
        else {
            userDetails[field] = 1
        }
        this.setState({ userDetails: userDetails }, () => this.fieldChanged(true, "error"))
    }

    handleGroupsCallback = (field, value) => {
        let formattedGroups = value.filter((value, index, self) =>
            index === self.findIndex((item) => (item.value === value?.value && item.text === value?.text))
        )
        let groups = formattedGroups?.map(item => ({
            group_id: item?.value || null,
            group_name: item?.text || null,
            user_group_id: null
        }))
        this.handleChange("user_group", groups);
    }

    handleSubmit = () => {
        let userData = this.state.userDetails
        userData.role_id === 1 ? userData.is_user_provisioner = 1 : userData.is_user_provisioner = 0
        this.setState({ postInitiated: true, isPosting: true, userDetails: userData })
        let canSubmit = this.state.userDetails.user_name && this.state.userDetails.role_id && this.state.userDetails.tenant_id && this.state.userDetails.email && validateEmail(this.state.userDetails.email)
        if (canSubmit) {
            this.postUserDetails(userData);
        }
        else {
            this.setState({ isPosting: false })
            console.log("Post JSON:", this.state.userDetails);
        }

    }

    postUserDetails = (userData) => {
        SchedulerService.postDataParams(Constants.SchedulerServiceBaseUrl + '/user', userData, 1)
            .then((response) => {
                if (!response.data.error) {
                    this.setState({ isPosting: false })
                    this.fieldChanged(false, "error");
                    this.fieldChanged(true, "success");
                    console.log("post response", response);
                }
                else {
                    this.setState({ isPosting: false })
                    this.fieldChanged(true, "fail");
                }
            },
                (err) => {
                    this.setState({ isPosting: false })
                    this.fieldChanged(true, "fail");
                    console.log("Post agency details error: " + err);
                });
    }

    render() {
        const { classes } = this.props;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props?.id)) ? this.state.saveEditMessage :
                (this.state.showSuccess && (typeof (this.props?.id) !== "Number")) ? `New User has been created!` : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failTitle :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <div className="MainViewModalContainer" >
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}
                    onClose={this.props?.handleClose}
                    hideBackdrop={true}
                    id="modal">
                    <MDBModalDialog size='md'>
                        <MDBModalBody>
                            <MDBModalContent>
                                <MDBContainer className='CreateOrEditUserModalContainer'>
                                    <MDBRow className="ModalHeader">
                                        <MDBCol md={5} className="HeaderText" >{this.props?.title}</MDBCol>
                                        <MDBCol md={6} className="HeaderText" ></MDBCol>
                                        <MDBCol md={1} className="closeIcon"
                                            onClick={this.closeState}
                                        ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                    </MDBRow>
                                    {this.state.loadingData ?
                                        <div className="ContentLoader">
                                            <CircularProgress />
                                        </div> :
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow>
                                                    <MDBCol md={6} id="textFieldURM">
                                                        <BasicTextField
                                                            label="User Name"
                                                            isMandatory={true}
                                                            limit={sizeLimits.nameCharacterLimit}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            value={this.state.userDetails.user_name}
                                                            showMandatory={this.state.postInitiated}
                                                            onChange={(e) => this.handleChange("user_name", e.target.value)}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md={6} id="activeCheck">
                                                        <BasicCheckbox
                                                            className="statusCheckbox"
                                                            id="statuschb"
                                                            label="Inactive"
                                                            checked={this.state.userDetails.is_active === 1 ? false : true}
                                                            onChange={(e) => this.handleCheck(e, "is_active")}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow id="selectFieldURM">
                                                    <MDBCol md={4}>
                                                        <SelectField
                                                            label="Role"
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            options={this.state.userRoleDetails}
                                                            value={this.state.userDetails.role_id}
                                                            onChange={(e) => this.handleChange("role_id", e.target.value)}
                                                        />
                                                    </MDBCol>
                                                    {/* <MDBCol>
                                                    <BasicCheckbox
                                                        className="isUserProvisioner"
                                                        id="isUserProvisioner"
                                                        label="User Provisioner"
                                                        checked={this.state.userDetails.is_user_provisioner}
                                                        onChange={(e) => this.handleCheck(e, "is_user_provisioner")}
                                                    />
                                                </MDBCol> */}
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={4}>
                                                        <SelectField
                                                            label="Tenant"
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            options={this.state.tenantDetails}
                                                            value={this.state.userDetails.tenant_id}
                                                            onChange={(e) => this.handleChange("tenant_id", e.target.value)}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={8} id="textFieldURM">
                                                        <BasicTextField
                                                            label="Account Email"
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            limit={sizeLimits.emailCharacterLimit}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            value={this.state.userDetails.email}
                                                            fieldValid={this.state.fieldValid === false ? true : this.state.existingEmail ? true : false}
                                                            inValidInput={this.state.fieldValid === false ? messages.invalidInput : this.state.existingEmail ? "Email Already Exists" : null}
                                                            onChange={(e) => this.handleChange("email", e.target.value)}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow id="groupField">
                                                    <MDBCol md={8}>
                                                    <BasicLabel text='Group Access' />

                                                    <SearchSelectField
                                                        label="Group Access"
                                                        width={'100%'}
                                                        value={this.state.userDetails?.user_group?.map(item => ({ value: item.group_id, text: item.group_name })) || []}
                                                        options={this.state.groupData}
                                                        onChange={(e, value) => this.handleGroupsCallback("user_group", value)}
                                                        multiple={true}
                                                        disabled={(this.state.userDetails?.role_id == 1 || this.state?.userDetails?.role_id == 2) ? true : false}
                                                    />
                                                    </MDBCol>
                                                </MDBRow>
                                                <br />
                                                <MDBRow>
                                                    <MDBCol md={8}></MDBCol>
                                                    <MDBCol md={2}>
                                                        <BasicButton text="Cancel" type="inline" variant="outlined" onClick={this.closeState} />
                                                    </MDBCol>
                                                    <MDBCol md={2}>
                                                        <BasicButton
                                                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props?.primaryButtonText}
                                                            type="inline"
                                                            variant="contained"
                                                            onClick={this.handleSubmit} />
                                                    </MDBCol>
                                                   
                                                </MDBRow>
                                                <br />
                                            </MDBCol>
                                        </MDBRow>
                                    }
                                </MDBContainer>
                                {/* </div> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
                <MessageModal
                    open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                    title={comfirmModalTitle}
                    message={message}
                    hideCancel={this.state.showFail || this.state.showSuccess || false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={this.onConfirm}
                    handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
            </div>
        );
    }
}
export default withUserContext(CreateOrEditUserModal);