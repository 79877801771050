import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { MDBIcon } from 'mdbreact';
import BasicLabel from '../BasicLabel/BasicLabel';
import './SelectField.scss';

export default class SelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            mandatoryWarning: "Please complete this mandatory field",
            selectUnion: "Please select Union",
            placeHolderText: props.placeHolderText ? props.placeHolderText : '-Select-'
        }
    }

    render() {
        let selectOptions = this.props?.options || [];
        let error = (this.props.showMandatory && !this.props?.value) || this.props.fieldValid || false;
        let showAsLabel = this.props.showAsLabel || false;
        const Placeholder = () => {
            return <div>{this.state.placeHolderText}</div>;
        };
        return (
            <div className={`SelectFieldContainer ${this.props?.className} ${error ? "error" : ""}`}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ? <div className="showAsLabel">{selectOptions?.find(option => option.value === this.props?.value)?.label || ""} </div> :
                    <Select
                        id={this.props?.id}
                        value={this.props?.value || ""}
                        variant="outlined"
                        displayEmpty
                        required={this.props?.isMandatory || false}
                        className={this.props?.className || ""}
                        disabled={this.props?.disabled || false}
                        onChange={this.props?.onChange}
                        onOpen={() => this.setState({ isOpen: true })}
                        onClose={() => this.setState({ isOpen: false })}
                        IconComponent={() => this.state.isOpen ? (
                            <MDBIcon icon="chevron-down" />
                        ) : (<MDBIcon icon="chevron-right" />)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            }
                        }}
                    >
                        {/* <MenuItem disabled value="">{this.props?.defaultMenuText || "- Select -"}</MenuItem> */}
                        {/* {...!this?.props?.isFirstOptionDisabled ? null : 'disabled' } */}
                        <MenuItem  {...!this?.props?.isFirstOptionDisabled ? null : 'disabled' }  value="">{this.props?.defaultMenuText || this.state.placeHolderText}</MenuItem>
                        {
                            selectOptions?.map((item, index) => {
                                return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                            })
                        }
                    </Select>}
                {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                    (this.props.fieldValid ? this.props.inValidInput : (this.props.disableSignatory ? this.state.selectUnion : ""))}</div>}
            </div>
        );
    }
}
