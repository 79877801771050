const configObjectAllUsers = {
    headings: [{
        headingLabel: "Name",
        icon:'sort-alpha-down',
        width: "20%"
    },
    {
        headingLabel: "Account Email",        
        width: "30%"
    },
    {
        headingLabel: "Group Access",       
        width: "30%"
    },
    {
        headingLabel: "Role",       
        width: "10%"
    },
    {
        headingLabel: "Tenant",
        width: "10%"
    },
    {
        headingLabel: "Status",
        width: "7%"
    },
    {
        headingLabel: "",       
        width: "3%"
    }],
    dataNodes: ["name", "account_email", "access_groups", "role", "tenant", "status"],
    primaryKey: "user_id",
    actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};

const allUsersList = [
    {
        id: 1,
        name: "Joe Schmoe",
        account_email: "Joe.Schmoe@warnerbros.com",
        access_groups: ["System Admin","Jerry Talmer","Jenny Taft"],
        role: "role",
        tenant: "tenant",
        status: "Active"
    },
    {
        id: 2,
        name: "Joe Schmoe",
        account_email: "Joe.Schmoe@warnerbros.com",
        access_groups: ["System Admin","Jerry Talmer","Jenny Taft"],
        role: "role",
        tenant: "tenant",
        status: "Inactive"
    },
    {
        id: 3,
        name: "Joe Schmoe",
        account_email: "Joe.Schmoe@warnerbros.com",
        access_groups: ["System Admin","Jerry Talmer","Jenny Taft"],
        role: "role",
        tenant: "tenant",
        status: "Active"
    },
    {
        id: 4,
        name: "Joe Schmoe",
        account_email: "Joe.Schmoe@warnerbros.com",
        access_groups: ["System Admin","Jerry Talmer","Jenny Taft"],
        role: "role",
        tenant: "tenant",
        status: "Active"
    },
    {
        id: 5,
        name: "Joe Schmoe",
        account_email: "Joe.Schmoe@warnerbros.com",
        access_groups: ["System Admin","Jerry Talmer","Jenny Taft"],
        role: "role",
        tenant: "tenant",
        status: "Active"
    },
    {
        id: 6,
        name: "Joe Schmoe",
        account_email: "Joe.Schmoe@warnerbros.com",
        access_groups: ["System Admin","Jerry Talmer","Jenny Taft"],
        role: "role",
        tenant: "tenant",
        status: "Active"
    },
]

const selectOptions = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "Inactive",
        value: 2
    },
]

const selectTenant = [
    {
        label: "Animation",
        value: 1
    },
    {
        label: "Television",
        value: 2
    },
    {
        label: "test",
        value: 3
    }
]

const selectProduction = [
    {
        text: "WestWorld",
        value: 1,
    },
    {
        text: "Shameless",
        value: 2,
    },
    {
        text: "Whatever",
        value: 3,
    }
]

export { configObjectAllUsers, allUsersList, selectOptions, selectProduction, selectTenant }