export const mockUserDetails = {
    "user_id": 0,
    "email": null,
    "is_active": 1,
    "is_user_provisioner": 0,
    "role_id": null,
    "role_name": null,
    "tenant_id": null,
    "tenant_name": null,
    "user_name": null,
    "user_group": []
}

const selectOptions = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "In-Active",
        value: 2
    },
]
const selectTenant = [
    {
        label: "Animation",
        value: 1
    },
    {
        label: "Television",
        value: 2
    },
    {
        label: "test",
        value: 3
    }
]

const selectRole = [
    {
        label: "Manager",
        value: 1,
    },
    {
        label: "Read only",
        value: 2,
    },
    {
        label: "Team Leader",
        value: 3,
    }
]
const selectNewGroup = [
    {
        label: "Kevin Parsnip",
        value: 1
    },
    {
        label: "Johhney Mcgeiver",
        value: 2
    },
    {
        label: "Shawn",
        value: 3
    }
]

export { selectOptions, selectTenant, selectRole, selectNewGroup }