import React from 'react';
import TableComponent from '../SharedComponents/Table';
import { Card } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MDBBtn } from 'mdb-react-ui-kit';
import { configObjectAllUsers, allUsersList, configObjectGroups, groupsList, tabList } from './config';
import "./usermanagement.scss";
import IconTextField from '../SharedComponents/IconTextField/IconTextField';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { handlePaginationList, updateTableSortConfigObject, applyFilterTable, tableSortList } from '../../Common/TableHelper';
import CreateOrEditUserModal from '../NewUserPopUp/CreateOrEditUserModal';
import TablePagination from '@material-ui/core/TablePagination';
// import CreateOrEditGroupModal from '../UserManagement/GroupModal/NewGroup';
import * as Constants from '../../constants/constants';
import SchedulerService from '../../services/service';
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from '../../contexts/UserContext';

export default withUserContext(class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            tabListData: [],
            searchTerm: null,
            listBackUp: [],
            list: [],
            page: 0,
            rowsPerPage: 10,
            ismodalOpen: false,
            isModal: false,
            titleGroup: "",
            list1: [],
            list2: [],
            newUserList: {},
            titleUser: "",
            userTitle: "",
            id: "",
            isFetchingGroupDetails: false,
            isFetchingUserDetails: false,
            currentList: [],
            emailList: [],
            sortBy:"Name"
        }
    }

    componentDidMount() {
        this.getUserDetails();
    }


    getUserDetails = () => {
        this.setState({ isFetchingUserDetails: true });
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + '/usersAll', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if(response?.data?.error){
                    console.log(response?.data?.error)
                    return;
                }
                let formattedList = response?.data?.map(list => {
                    return {
                        "name": list?.user_name,
                        "account_email": list?.email,
                        "access_groups": list?.user_group?.length > 0 ? list?.user_group?.map((item, index) => (index ? ', ' : '') + item.group_name): '',
                        "role": list?.role_name,
                        "tenant": list?.tenant_name,
                        "status": list?.is_active === 1 ? "Active" : "Inactive",
                        "user_id": list?.user_id
                    }
                })
                let emailList = response?.data?.map(item => { return { "email": item?.email?.toLowerCase() } })
                this.setState({ list1: formattedList, list: handlePaginationList(this.state.page, this.state.rowsPerPage, formattedList), currentList: formattedList, listBackUp: formattedList, isFetchingUserDetails: false, emailList: emailList });
            },
                (err) => {
                    this.setState({ isFetchingUserDetails: false });
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    handleSearch = (value) => {
        this.setState({
            searchTerm: value
        });
        if (value) {
            let filteredList = this.state.listBackUp?.filter(item => item.name?.toLowerCase().includes(value.toLowerCase()));
            this.setState({ list1: handlePaginationList(0, this.state.rowsPerPage, filteredList), currentList: filteredList });
        } else {
            this.setState({ list1: handlePaginationList(0, this.state.rowsPerPage, this.state.listBackUp), currentList: this.state.listBackUp });
        }
        // switch (this.state.tabValue) {
        //     case 0:
        //         if (value) {
        //             let filteredList = this.state.listBackUp?.filter(item => item.name?.toLowerCase().includes(value.toLowerCase()));
        //             this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, filteredList), currentList: filteredList });
        //         } else {
        //             this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, this.state.listBackUp), currentList: this.state.listBackUp });
        //         }
        //         break;

        //     case 1:
        //         if (value) {
        //             let filteredList = this.state.listBackUp?.filter(item => item.groupname?.toLowerCase().includes(value.toLowerCase()));
        //             this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, filteredList), currentList: filteredList });
        //         } else {
        //             this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, this.state.listBackUp), currentList: this.state.listBackUp });
        //         }
        //         break;
        // }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            list: handlePaginationList(newPage, this.state.rowsPerPage, this.state.currentList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            list: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }


    handleOpenGroup = (id) => {
        this.setState({
            isModal: true,
            id: id === undefined ? "" : id,
            titleGroup: id === undefined ? "New Group" : "Edit Group"
        });
    }


    handleOpenUser = (id) => {
        console.log("Id: ", id, id === undefined ? "New User" : "Edit User");
        this.setState({
            ismodalOpen: true,
            id: typeof(id) !== 'number' ? null : id,
            titleUser: typeof(id) !== 'number' ? "New User" : "Edit User",          
        });
        this.handleSearch('')
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            if(this.state.sortCount === 0)
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
            else this.setState({ sortCount: 0 }, () => {
                this.handleTableSort(column);
            })
        }
       
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? false : true,
            config: updateTableSortConfigObject(configObjectAllUsers, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                list1: handlePaginationList(this.state.page, this.state.rowsPerPage,
                    tableSortList("Name", this.getSortNode(colName), this.state.currentList, this.state.isSortByAsc))
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Name": return "name";
        }
    }

    render() {
        let isLoading = this.state.isFetchingUserDetails || this.state.isFetchingGroupDetails;
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        return (
            <>
                {userPermissions?.canAccessURM  ?
                <div>
                    <div className="User-Management">
                        <div className="MainModel">
                            <div className="headerText">
                                <div className='heading'>
                                    <h5 className="header">User Management</h5>
                                </div>
                                <div className="ml5">
                                    <IconTextField placeholder={'Search Users'}
                                        value={this.state.searchTerm}
                                        onChange={(e) => this.handleSearch(event.target.value)}
                                        icon='search'
                                    />
                                </div>
                            </div>
                            <div className="userButton">
                                <BasicButton
                                    text="New User"
                                    icon="plus-circle"
                                    variant="outlined"
                                    onClick={this.handleOpenUser}
                                />
                            </div>
                        </div>
                        <br />
                        <div>
                            {((userPermissions?.canCreateNewUser && this.state?.titleUser == "New User") || (userPermissions?.canEditUser && this.state?.titleUser == "Edit User")) && this.state.ismodalOpen ?
                                <CreateOrEditUserModal
                                    open={this.state.ismodalOpen}
                                    title={this.state.titleUser}
                                    primaryButtonText={"Save"}
                                    secondaryButtonText={"Cancel"}
                                    AddButtonText={"Add"}
                                    onConfirm={() => alert("Button Clicked")}
                                    handleClose={(e) => this.setState({ ismodalOpen: false })}
                                    id={this.state.id ? this.state.id : null}
                                    handleRefresh={this.getUserDetails}
                                    emailList={this.state.emailList}
                                />
                                : null}
                        </div>
                        <div id="table-scroll">
                            <TableComponent                            
                                list={this.state.list1}
                                config={configObjectAllUsers}
                                isLoading={this.state.isFetchingUserDetails}
                                editCallback={this.handleOpenUser}
                                sortCallback={this.handleTableColumnClick}
                            />
                            {/* <TablePagination
                                component="div"
                                count={this.state.currentList ? this.state.currentList?.length : 0}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            /> */}
                        </div>
                    </div>
                </div>
                :
                    <div>You do not have permissions to manage users/groups</div>
                }
            </>
        )
    }
}
);