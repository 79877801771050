import React from 'react';
import TextField from '@material-ui/core/TextField';
import BasicLabel from '../BasicLabel/BasicLabel';
import './BasicTextField.scss';

export default class BasicTextField extends React.Component {
    render() {
        let error = this.props?.value?.length > this.props.limit || (this.props.showMandatory && !this.props?.value) ||
            this.props.fieldValid || false;
        let mandatoryWarning = "Please complete this mandatory field";
        let showAsLabel = this.props.showAsLabel || false;
        return (
            <div className={this.props?.disabled ? ( "BasicTextFieldContainer Field-disabled") :
                error ? "BasicTextFieldContainer error" : "BasicTextFieldContainer"}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ? <div className="showAsLabel">{this.props.value || ""} </div> :
                    <TextField id={this.props?.id}
                        variant="outlined"
                        autoComplete='off'
                        type={this.props?.type || 'text'}
                        className={this.props?.className || ""}
                        value={this.props?.value || ""}
                        onChange={this.props?.onChange}
                        inputProps={this.props?.inputProps || {}}
                        InputProps={this.props?.InputProps || {}}
                        disabled={this.props?.disabled || false}
                        placeholder={this.props?.placeholder || ""}
                        required={this.props?.isMandatory || false}
                        onKeyUp = {this.props?.onKeyUp || null}
                        readOnly={this.props?.readOnly || false}
                    />}
                {error && !showAsLabel && <div className="errorText">{((this.props.showMandatory && !this.props?.value) ? mandatoryWarning :
                    this.props.fieldValid ? this.props.inValidInput : this.props.limitWarning) || ""}</div>}
            </div>
        );
    }
}

// BasicTextField.defaultProps = {
//     onKeyUp: () => null
// }
