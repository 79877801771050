import moment from 'moment'
export const getFormattedDate = (date, dateField = false) => {
    
    if(date){
      
    let formattedDate = new Date(date+'T00:00:00')
    let year = formattedDate.getFullYear();
    let month = (1 + formattedDate.getMonth()).toString().padStart(2, '0');
    let day = formattedDate.getDate().toString().padStart(2, '0');
    if (dateField)
        return year + '-' + month + '-' + day;
        // return moment(date)?.utc().format('YYYY-MM-DD');
    else
        return month + '/' + day + '/' + year;
        // return moment(date)?.utc().format('MM/DD/YYYY');
    }
}
export const getAge = (currentDate) => {
    if(currentDate){
        var today = new Date();
        var birthDate = new Date(currentDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

export const getAddress = (field,item,value,addressConfig)=>{
        item[field] = value;
        let a = value.split('\n');
        item[addressConfig.address1] = !(a[0]) ? null : a[0]; 
        item[addressConfig.address2] = !(a[1]) ? null : a[1]; 
        let temp = '';
        for(var i=2;i<a.length;i++){
            temp+=a[i];
        }
        item[addressConfig.address3] = !temp ? null : temp;
        return item;
}

export const getFormattedTime = (date) => {
    let formattedDate = new Date(date);
    let hours = formattedDate.getHours();
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12 || 12;
    hours = hours < 10 ? '0' + hours : hours;
    let mins = formattedDate.getMinutes();
    mins = mins < 10 ? '0' + mins : mins;
    return hours + ':' + mins + " " + ampm;
}

export const getDateAndTime = (dateEntry) => {
    let date = getFormattedDate(dateEntry);
    let time = getFormattedTime(dateEntry);
    return date + ", " + time;
}

export const validateEmail = (email) => {
    var emailRegex = /^[A-Za-z0-9]+([\.-][A-Za-z0-9]+)*@[A-Za-z0-9]+([\.-][A-Za-z0-9]+)*(\.[A-Za-z]{2,3})+$/;
    return emailRegex.test(email);
}

export const maskSsn = (ssn) => {
    return ssn ? `xxx-xxx-${ssn?.substr(ssn?.length - 4)}` : ssn;
}

// export const validatePhone = (phone) => {
//     let phoneRegex = /^[0-9\-\(\)+]+$/;
//     return phoneRegex.test(phone);
// }

export const validateCharctersOnly = (text) => {
    let regex = /^[a-zA-Z]*$/;
    return regex.test(text);
}

export const validateCharctersSpaceOnly = (text) => {
    let regex = /^[a-zA-Z ]*$/;
    return regex.test(text);
}

export const validateNumbersOnly = (text) => {
    let regex = /^[0-9]*$/;
    return regex.test(text);
}

export const validateCharNumOnly = (text) => {
    let regex = /^[a-zA-Z0-9]*$/i;
    return regex.test(text);
}

export const validateCharNumHyphenSlashOnly = (text) => {
    let regex = /^[a-zA-Z0-9-/]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphenSlash = (text) => {
    let regex = /^[a-zA-Z0-9-/]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphen = (text) => {
    let regex = /^[a-zA-Z0-9-]*$/i;
    return regex.test(text);
}

export const validateCharNumCommaDotWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9,. ]*$/i;
    return regex.test(text);
}

export const validateCharNumOnlyWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9 ]+\s?[a-zA-Z0-9 ]*$/;
    return text ? regex.test(text) : true;
}

export const validateNumPlusOnly = (text) => {
    let regex = /^[0-9+]*$/;
    return regex.test(text);
}

export const validateCharNumHashOnly = (text) => {
    let regex = /^[a-zA-Z0-9#]*$/i;
    return regex.test(text);
}

export const validateCharNumRateOnly = (text) => {
    let regex = /^[a-zA-Z0-9@]*$/i;
    return regex.test(text);
}

export const validateCharNumHashSpaceOnly = (text) => {
    // let regex = /^[a-zA-Z0-9#]+\s?[a-zA-Z0-9#]*$/i;
    let regex = /^[a-zA-Z0-9# ]/i;
    return regex.test(text);
}

export const validateNumHyphenOnly = (text) => {
    let regex = /^[0-9-]*$/;
    return regex.test(text);
}

export const s2ab = (s) => {

    var buf = new ArrayBuffer(s.length);

    var view = new Uint8Array(buf);

    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;

    return buf;

  }
  export const debounce=(cb, delay = 250) =>{
    let timeout
  
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }