import React, { Component } from 'react'
import { MDBContainer , MDBRow, MDBCol} from 'mdb-react-ui-kit'
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField'
import {withUserContext} from '../../contexts/UserContext'
import {ResourceDetails} from "./Config"
import BasicButton from '../SharedComponents/BasicButton/BasicButton' 
import {validateNumbersOnly, validateCharNumOnlyWithSpace} from "../../Common/Helper"
import Messages from "../../Common/Messages.json"
import sizeLimit from "../../Common/SizeLimits.json"
import { CircularProgress } from '@material-ui/core';
import "./Resource.scss"
import SchedulerService from "../../services/service"
import * as Constants from '../../constants/constants'
class AddEditResource extends Component {
    constructor(props){
        super(props)
        this.state={
            resourceDetails:ResourceDetails,
            postInitiated:false,
            showLoader:false,
            resource_id:null
        }
    }
    componentDidMount(){
        if(this.props?.id){
            this.getResource();
        }
    }
    getResource(){
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl +"/resource?resourceId="+this.props?.id,this.props?.userContext?.active_tenant?.tenant_id)
        .then((response)=>{
            console.log(response);
            this.setState({resourceDetails:{...ResourceDetails,...response?.data?.[0]}})
        },err=>{
            this.props.fieldChanged(true, "fail");
        })
    }
    handleChange = (field, value) => {
        this.setState(prevState => ({
            resourceDetails: {
                ...prevState.resourceDetails,
                [field]: value || null
            }
        }))
    }
    checkFieldLength = (field, limit) => {
        return field ? field.length <= limit : true
    }
    validateBeforSubmit(postData){
        return postData?.color &&
                postData?.resource_name && this.checkFieldLength(postData?.resource_name,sizeLimit.nameCharacterLimit) &&
                postData?.occupancy && this.checkFieldLength(postData?.occupancy?.toString(),sizeLimit.threeDigitNumber) &&
                postData?.technology && this.checkFieldLength(postData?.technology,sizeLimit.nameCharacterLimit)
       
    }
    postResource(){       
        this.setState({postInitiated:true,showLoader:true})
        let postData = {...this.state?.resourceDetails}
        if(!this.validateBeforSubmit(postData)){
            this.setState({showLoader:false})
            return;
        }
        SchedulerService.postDataParams(`${Constants.SchedulerServiceBaseUrl}/resource`, postData,this.props?.userContext?.active_tenant?.tenant_id)
        .then((response) => {
            this.setState({ postInitiated: false,showLoader:false });
            if(response?.data?.error_code || response.data.error){
                this.props.fieldChanged(true, "fail",response?.data?.error_code ? response?.data?.error_message : undefined);
                return;
            } 
            console.log(response);
            this.props?.setCallRefresh("Resource",true)
            this.props.fieldChanged(true, "success");
            // this.props?.toggleRefreshPage("Resource");
        }, (err) => {
            this.props.fieldChanged(true, "fail");
            this.setState({ postInitiated: false,showLoader:false });
        })
        // alert("submitting")
    }
  render() {
    return (
     <MDBContainer fluid className='mt-2 AddEditResourceContainer'>
         <MDBRow>
             <MDBCol md={1}>
             <BasicTextField
                 label={"Color"}
                 id={"color"}
                 type={'color'}
                 className='colorField'
                 value={this.state?.resourceDetails?.color}
                 onChange={(e)=>this.handleChange('color',e.target.value)}
                 isMandatory={true}
                 showMandatory={this.state.postInitiated || false}
                 />
             </MDBCol>
             <MDBCol md={6}>
                 <BasicTextField
                 id={"resourceName"}
                 label={"Resource Name"}
                 limit={sizeLimit.nameCharacterLimit}
                 limitWarning={Messages.exceed50CharacterWarning}
                 value={this.state?.resourceDetails?.resource_name}
                 onChange={(e)=>validateCharNumOnlyWithSpace(e.target.value) ? this.handleChange('resource_name',e.target.value):null}
                 isMandatory={true}
                 showMandatory={this.state.postInitiated || false}
                 />
             </MDBCol>
         </MDBRow>
         <MDBRow>
            
             <MDBCol md={3}>
                 <BasicTextField
                 id={"occpancy"}
                 label={"Occupancy"}
                 limit={sizeLimit.threeDigitNumber}
                 limitWarning={Messages.exceed3CharactersWarning}
                 value={this.state?.resourceDetails?.occupancy}
                 onChange={(e)=>validateNumbersOnly(e.target.value) ? this.handleChange('occupancy',e.target.value):null}
                 isMandatory={true}
                 showMandatory={this.state.postInitiated || false}
                 />
             </MDBCol>
         </MDBRow>
         <MDBRow>
            
             <MDBCol md={12}>
                 <BasicTextField
                 id={"technology"}
                 label={"Technology"}
                 limit={sizeLimit.nameCharacterLimit}
                 limitWarning={Messages.exceed50CharacterWarning}
                 value={this.state?.resourceDetails?.technology}
                 onChange={(e)=>validateCharNumOnlyWithSpace(e.target.value) ?this.handleChange('technology',e.target.value):null}
                 isMandatory={true}
                 showMandatory={this.state.postInitiated || false}
                 />
             </MDBCol>
         </MDBRow>
         <div className="modalFooterBtn flex">
                    <BasicButton
                        variant='outlined'
                        text="Cancel"
                        type="inline"
                        onClick={this.props?.onClose}
                       
                       
                    />
                    
                     <BasicButton                       
                        text={this.state?.showLoader ? <CircularProgress color="inherit" size={20}/>: this.props?.mode == "New" ? "Create": "Save"}
                        type="inline"
                        disabled={this.props?.mode === "New" && !this.props?.userContext?.active_tenant?.permissions?.canAddResources ? true : this.props?.mode === "Edit" && !this.props?.userContext?.active_tenant?.permissions?.canEditResources ? true  : false}
                        onClick={this.postResource.bind(this)}
                    />
                </div>
     </MDBContainer>
    )
  }
}
export default withUserContext(AddEditResource)
