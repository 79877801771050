import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {  MDBSelect, MDBIcon } from 'mdb-react-ui-kit';
import BasicLabel from '../BasicLabel/BasicLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText"
import './SelectFieldHeader.scss';

export default class SelectFieldHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            mandatoryWarning: "Please complete this mandatory field",
            selectUnion: "Please select Union",
            placeHolderText: props.placeHolderText ? props.placeHolderText : '-Select-'
        }
    }

    render() {
        console.log("selectfield Header:",this.props?.value);
        let selectOptions = this.props?.options || [];
        let error = (this.props.showMandatory && !this.props?.value) || this.props.fieldValid || false;
        let showAsLabel = this.props.showAsLabel || false;
        const Placeholder = () => {
            return <div>{this.state.placeHolderText}</div>;
        };
        return (
            <div className={`SelectFieldHeaderContainer ${this.props?.className} ${error ? "error" : ""}`}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ? <div className="showAsLabel">{selectOptions?.find(option => option.value === this.props?.value)?.label || ""} </div> :
                    this.props.multiple == true ? 
                   
                        <Select                    
                        id={this.props?.id}
                        value={this.props?.multiple ? this.props?.value : []}
                        variant="outlined"    
                        displayEmpty                    
                        required={this.props?.isMandatory || false}
                        className={this.props?.className || ""}
                        disabled={this.props?.disabled || false}
                        onChange={this.props?.onChange}    
                        style={{ width: this.props?.width ? this.props?.width : '100%' }}
                        onOpen={() => this.setState({ isOpen: true })}
                        onClose={() => this.setState({ isOpen: false })}
                        multiple={this.props?.multiple || false}
                        IconComponent={() => this.state.isOpen ? (
                            <MDBIcon icon="chevron-down" />
                        ) : (<MDBIcon icon="chevron-right" />)}                   
                       
                        renderValue={(selected) => {  
                            // if(selected?.length == 0){
                             return this.props?.placeHolderText;                           
                            // } 
                            // return selected?.join(',')                        
                          }}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            }
                        }}>
                            
                        {/* {(this.props?.placeHolderText || this.props?.defaultMenuText) &&  */}
                         {/* <MenuItem  {...!this?.props?.isFirstOptionDisabled ? null : 'disabled' }  value="">{this.props?.placeHolderText}</MenuItem> */}
                        {/* } */}
                        {/* {
                            selectOptions?.map((item, index) => {
                                return <MenuItem key={index} value={item.value} selected={item.value == this.props?.value}>{item.label}</MenuItem>
                            })
                        } */}
                        {selectOptions.map((name,index) => (
                            <MenuItem key={index} value={name.value}>
                            <Checkbox color={'primary'} checked={this.props?.value.indexOf(name.value) > -1} />
                            <ListItemText primary={name.label} />
                            </MenuItem>
                        ))}
                        </Select> 
                    
                        :
                        <Select                    
                        id={this.props?.id}
                        value={this.props?.value || ""}
                        variant="outlined"    
                        displayEmpty                    
                        required={this.props?.isMandatory || false}
                        className={this.props?.className || ""}
                        disabled={this.props?.disabled || false}
                        onChange={this.props?.onChange}    
                        style={{ width: this.props?.width ? this.props?.width : '100%' }}
                        onOpen={() => this.setState({ isOpen: true })}
                        onClose={() => this.setState({ isOpen: false })}
                        IconComponent={() => this.state.isOpen ? (
                            <MDBIcon icon="chevron-down" />
                        ) : (<MDBIcon icon="chevron-right" />)}                   
                        // IconComponent={() =>  (<MDBIcon icon="chevron-down" />)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            }
                        }}>
                        {/* {(this.props?.placeHolderText || this.props?.defaultMenuText) &&  */}
                         <MenuItem  disabled={this?.props?.isFirstOptionDisabled ? true : false}   value="">{this.props?.placeHolderText}</MenuItem>
                        {/* } */}
                        {
                            selectOptions?.map((item, index) => {
                                return <MenuItem key={index} disabled={item?.disabled ? true : false} value={item.value} selected={item.value == this.props?.value}>{item.label}</MenuItem>
                            })
                        }
                    </Select> 
                    }                   
                    
                {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                    (this.props.fieldValid ? this.props.inValidInput : (this.props.disableSignatory ? this.state.selectUnion : ""))}</div>}
            </div>
        );
    }
}
