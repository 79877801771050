import React from "react";
import { MDBTooltip, MDBIcon } from 'mdb-react-ui-kit';
function renderEventContent(eventInfo) {
    // console.log("eventInfo",eventInfo)
   
    return (
      <div className="eventWrapper" onDoubleClick={()=>eventInfo?.event?.extendedProps?.editClick(eventInfo,'edit')}>
       <MDBTooltip tag='div' wrapperClass="tootlipWrapper" placement="auto-start"  title={
        <>
           <span>{eventInfo?.event?.extendedProps?.timeText}</span><br/>
          { eventInfo?.event?.extendedProps?.canViewSubject && <><span>{eventInfo?.event?.title}</span> <br/> </> }
         
          <span className="userName">{eventInfo?.event?.extendedProps?.author}</span>
        </>
      }>  
      {/* <br/> */}
      <div className={eventInfo?.event?._def?.extendedProps?.meetingsClassName}>{eventInfo?.event?.title}</div>
      {/* {(eventInfo?.event?.extendedProps?.canEditMeeting || eventInfo?.event?.extendedProps?.canDeleteMeeting) &&
        <span className="iconWrapper">
       {eventInfo?.event?.extendedProps?.canEditMeeting && <MDBIcon fas icon="pen" onClick={()=>eventInfo?.event?.extendedProps?.editClick(eventInfo,'edit')}/> }         
       {eventInfo?.event?.extendedProps?.canDeleteMeeting && <MDBIcon fas icon="trash" onClick={()=>eventInfo?.event?.extendedProps?.editClick(eventInfo,'delete')}/>}
        </span>
        }   */}
        {/* <div className="truncate">{eventInfo?.event?.extendedProps?.timeText}</div> 
      
        
        <div className="userName truncate">{eventInfo?.event?.extendedProps?.author}</div> */}
      </MDBTooltip>
      </div>
    )
  }

  export default renderEventContent;